import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import { Page } from '../../components';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink
} from '../../components';

import css from './SeoPage.css';

const SeoPage = () => {
  const { siteTwitterHandle, siteFacebookPage } = config;

  // prettier-ignore
  return (
    <Page
      title="Wypożyczalnia kajaków w Decathlon Rent. Wynajmij na spływ kajakowy!"
      description="Decathlon Rent to wypożyczalnia kajaków i wiele innych sprzętów wodnych. Sprawdź się w sportach wodnych z nami!"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Decathlon Rent to wypożyczalnia kajaków i wiele innych sprzętów wodnych. Sprawdź się w sportach wodnych z nami!',
        name: 'Wypożyczalnia kajaków w Decathlon Rent. Wynajmij na spływ kajakowy!',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          {/* <TopbarContainer /> */}
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <img src="https://prod-rent-backend-a9sd29me0v9sv.s3.eu-west-1.amazonaws.com/prod/stat-img/logo2.png" className={css.logo}/>
          <h1 className={css.pageTitle}>Decathlon Rent - wypożyczalnia kajaków w twoim mieście</h1>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    'businessCategory=watersports_kayak&category=watersports&exact=true&keywords=Kajaki',
                }}
                className={css.searchLink}
              >
                Sprawdź
              </NamedLink>
              <p>Sporty wodne, a zwłaszcza kajakarstwo cieszą się ogromną popularnością, szczególnie w okresie wakacyjnym. Nie tylko duże miasta, jak Warszawa, Poznań czy Trójmiasto, ale i mniejsze Katowice, Rzeszów lub Olsztyn, mogą być świetnym punktem startowym w ujęciu turystycznym. Spływy kajakowe to świetna rozrywka dla dorosłych i dzieci. Organizowane są praktycznie na terenie całej Polski, więc możliwości do uprawiania tego sportu jest naprawde wiele. Kajakarstwo to sezonowa dyscyplina sportu, dlatego nie każdy decyduje się na zakup własnego sprzętu. Jeśli więc planujesz uprawiać je okazjonalnie lub nie masz wystarczającej ilości miejsca do przechowywania akcesoriów sportowych, możesz skorzystać z wynajmu okresowego. Wypożyczalnia kajaków Decathlon Rent oferuje duży wybór kajaków wraz z niezbędnymi dodatkami. Możesz wybrać model, który odpowiada Ci najbardziej spośród listy produktów dostępnych w naszej oferty. Korzystając z naszych usług masz pewność, że sprzęty są serwisowane i utrzymane w doskonałym stanie, co ma ogromny wpływ na Twój komfort użytkowania i bezpieczeństwo. </p>
              
              <h2>Wypożyczenie kajaków na spływ kajakowy w Katowicach - poznaj ofertę Decathlon Rent </h2>
              <p>Katowice oferują mieszkańcom Górnego Śląska wiele miejsc, w które da się szybko dotrzeć, by móc uprawiać ulubione sporty wodne. W okolicy znajdują się zbiorniki wodne i rzeki, które przyciągają sympatyków kajakarstwa. Gdzie można się udać? Jednym z popularniejszych jest spływ kajakowy Kanałem Gliwickim. Nieco dalej na północny zachód już kusi Mała Panew, gdzie wodne szaleństwo startuje z miejscowości Krupski Młyn. Początkujący kajakarze powinni przetestować swoje umiejętności rybnickiej rzeki Rudy, gdzie szlak jest nie tylko bardzo łatwy, ale i dość malowniczy.</p>
              <p>Jak widać, Katowice są całkiem dobrą bazą wypadową, dlatego dużą popularnością w tym regionie cieszy się wynajem kajaków pneumatycznych. Katowicka wypożyczalnia kajaków Decathlon Rent oferuje duży wybór sprzętu i akcesoriów. Wystarczy tylko pamiętać o zabraniu wiosła i nieprzemakalnego worka, który zabezpieczy Twój bagaż. Zapewniamy wygodną i szybką realizację transakcji online oraz atrakcyjne ceny. Nasze kajaki świetnie sprawdzą się na wszystkich akwenach wodnych w Polsce i za granicą, zabierz je nad jezioro i rzekę lub nad morze. Zapakowane w wygodne do przenoszenia torby sprawią, że transport kajaka nie sprawi Ci najmniejszych trudności. </p>
              <p>Sprawdź szczegóły wypożyczenia kajaka, który cię interesuje, wybierz dogodny termin i oddział, w którym chcesz odebrać sprzęt. Możesz z niego swobodnie korzystać aż do zakończenia spływu. Za pośrednictwem naszej strony wygodnie i szybko zarezerwujesz wybrany przez siebie model kajaka, a odbierzesz go w oddziale sklepu Decathlon. Zapraszamy!</p>
              
              <h2>Kajak dwuosobowy lub większy? Sprawdź co nasza wypożyczalnia kajaków oferuje w Poznaniu</h2>
              <p>Miłośnicy sportów wodnych i kajakarstwa w Poznaniu nie muszą daleko szukać miejsc, do uprawiania swoich ulubionych aktywności. Przez sam środek miasta przepływa Warta, po której często organizowane są spływy kajakiem. Woda jest tu wyjątkowo spokojna, a warunki idealne nie tylko dla początkujących, ale i profesjonalistów. Poznań ma do zaoferowania spływy nie tylko wartą. Do dyspozycji kajakarzy jest też Noteć, Gopło czy Kanał Mosiński. 11-kilometrowy odcinek pomiędzy Borkowicami a Rogalinkiem w tymże kanale, to trzy godziny świetnej zabawy.</p>
              <p> Rozpoczynając przygodę z kajakarstwem wcale nie musisz od razu kupować nowego sprzętu. Wynajem kajaków w Decathlon Rent będzie świetną alternatywą. To także bardzo dobre rozwiązanie jeśli myślisz o zakupie, ale chcesz wcześniej przetestować wybrany model. Korzystanie z usług Decathlon Rent daje spory komfort - zarówno pod względem wygody (przechowywanie, konserwacja), jak i ceny. Kajak wypożyczyć można już za kilkadziesiąt złotych na dzień, wystarczy dobrać odpowiedni model. Wyboru można dokonać na własną rękę, korzystając z usługi online, bądź też skorzystać z fachowego doradztwa.</p>
              
              <h2>Decathlon Rent Warszawa - sprawdź jakie kajaki pompowane nasza wypożyczalnie oferuje Klientom</h2>
              <p>Wybierasz się na spływ, lub chcesz zabrać kajak na kilkudniowy biwak na pole namiotowe nad jeziorem? Wynajęcie kajaka w Decathlon Rent w Warszawie będzie idealnym pomysłem. Duży wybór różnorodnego sprzętu, łatwa dostępność i odbiór w wybranej placówce na terenie miasta to nie jedyne zalety. Postaw na nasze profesjonalne usługi i sprawdzony, regularnie serwisowany sprzęt. Skorzystaj z atrakcyjnych cen, wynajmij ekwipunek, którego potrzebujesz online i ciesz się z możliwości uprawiania swojej ulubionej dyscypliny sportowej.</p>
              <p>Zastanawiasz się co Warszawa ma do zaoferowania sympatykom sportów wodnych? Poza spływami organizowanymi Wisłą, kajakarze mają do dyspozycji także Narew. Jest tu dostępna wyjątkowa trasa, która obejmuje około 70 kilometrów od Nieporętu do przystani Suchodół. Na taką wycieczkę warto zarezerwować przynajmniej dwa dni, a więc solidny weekend. Wypożyczalnia kajaków w Warszawie może być też punktem początkowym dla ekip pragnących wybrać się nad lewy dopływ Wisły, czyli Bzurę. Jest też mniej znany Świder, tutaj do dyspozycji trasa blisko 28-kilometrowa. Jak widać, również w tym regionie Polski nie brakuje możliwości, trzeba jedynie znaleźć czas i dysponować odpowiednim sprzętem. w tym ostatnim jesteśmy w stanie Ci pomóc!</p>
              
              <h2>Wynajem kajaków w Olsztynie - sprawdź cennik i warunki rezerwacji</h2>
              <p>W trzech zupełnie odmiennych punktach na mapie — województwach śląskim, wielkopolskim i mazowieckim — trzy największe miasta oferują w swym pobliżu sporo atrakcji. Olsztyn natomiast jednoznacznie kojarzy się z niezwykle malowniczą, dość spokojną rzeką Krutynią. Wypożyczalnia pompowanych kajaków w tym przypadku przyda się przy dokonaniu rezerwacji na więcej, niż jeden dzień. Szlak ten będzie przyjemny nawet na kilka dni aktywnego wypoczynku.</p>
              <p>Procedura wypożyczenia kajaków w Decathlon Rent jest prosta i wygodna. Zamówienia dokonujesz online wybierając model sprzętu, zaznaczając daty i punkt odbioru. Wszystko to oczywiście z możliwością anulowania, gdyby Twoje plany uległy zmianie. Nasza usługa bazuje na systemie obniżających się cen - im dłuższy okres rezerwacji, tym niższa cena. Nie zwlekaj, sprawdź ofertę i wybierz kajak, który zabierzesz na spływ rzeką lub nad jezioro. </p>
              
              <h2>Gdzie wypożyczyć kajak w Trójmieście - przyjdź do Decathlon Rent</h2>
              <p>Trójmiasto to zdecydowanie region, w którym sporty wodne cieszą się ogromną popularnością i są uprawiane przez wiele osób. <a href="https://rent.decathlon.pl/kategoria/wypozyczalnia-kajakow/">Wypożyczalnia kajaków pneumatycznych i pompowanych</a> w Trójmieście działa na takich samych zasadach, jak w innych częściach Polski - zamówienie z rezerwacją terminu składasz online, a produkt odbierasz w wybranym oddziale. W razie jakichkolwiek pytań możesz też skorzystać z doradztwa naszych konsultantów, którzy z pewnością odpowiedzą na Twoje pytania. </p>
              <p><a href="https://rent.decathlon.pl/wypozyczalnia-sprzetu-sportowego">Wypożyczenie sprzętu sportowego</a> można potraktować również jako doskonałą metodę przetestowania go przed zakupem. Pozwala to uniknąć błędów zakupowych, związanych z indywidualnymi preferencjami, które pojawiają się niemal zawsze w takich przypadkach. Wypożyczalnia kajaków w Trójmieście Decathlon Rent charakteryzuje się tym, że do jej oferty trafiają nowości produktowe dostępne w stałej sprzedaży. Można zatem mieć pewność, że odbierany z punktu sprzęt nie będzie wyeksploatowanym, przestarzałym modelem. Zatem gdzie można kajak zabrać ze sobą, będąc w Gdańsku? Najlepszym kierunkiem pozostają Kaszuby, gdzie do dyspozycji jest wiele odcinków rzecznych tras średnich i łatwych. Na wyjazd warto zatem zarezerwować nie jeden dzień, nie weekend, a przynajmniej kilka dni wolnego, aby w pełni nacieszyć się spotkaniem z naturą.</p>
              <p>Wypożyczalnia kajaków Decathlon Rent funkcjonuje w pełni online, z możliwością dokonania rezerwacji i opłaty przez internet. Cały proces zajmuje mniej niż minutę, wystarczy kilka kliknięć. Oczywiście najtrudniejszy moment to wybór odpowiedniego sprzętu, bo baza jest tu ogromna. Dlatego w punktach odbioru usytuowanych w największych miastach w Polsce (np. Warszawa, Katowice, Poznań) przy sklepach sieci Decathlon czekają na ciebie specjaliści, którzy chętnie doradzą i dobiorą kajaki odpowiednie do potrzeb danej grupy. </p>

            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export default SeoPage;
