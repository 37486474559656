import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import { Page } from '../../components';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink
} from '../../components';

import css from './SeoPage.css';

const SeoPage = () => {

  // prettier-ignore
  return (
    <Page
      title="Wypożyczalnia desek SUP (Stand Up Paddle) | Decathlon Rent"
      description="Wypożyczalnia SUP Decathlon Rent oferuje możliwość wynajmu różnego rodzaju desek Stand Up Paddle. Sprawdź asortyment i zarezerwuj model, który Cię interesuje!"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Wypożyczalnia SUP Decathlon Rent oferuje możliwość wynajmu różnego rodzaju desek Stand Up Paddle. Sprawdź asortyment i zarezerwuj model, który Cię interesuje!',
        name: 'Wypożyczalnia desek SUP (Stand Up Paddle) | Decathlon Rent',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          {/* <TopbarContainer /> */}
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <img src="https://prod-rent-backend-a9sd29me0v9sv.s3.eu-west-1.amazonaws.com/prod/stat-img/logo2.png" className={css.logo}/>
          <h1 className={css.pageTitle}>Decathlon Rent - wypożyczalnia desek SUP (Stand Up Paddle) w Twoim mieście</h1>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    'businessCategory=watersports_sup&category=watersports&exact=true&keywords=Deski%20SUP',
                }}
                className={css.searchLink}
              >
                Sprawdź
              </NamedLink>
              <p>Pływanie na deskach SUP z roku na rok cieszy się coraz większym zainteresowaniem wśród miłośników sportów wodnych. Wiele osób zastanawia się czy warto kupować SUP 'a, a jeśli tak to jakiego. Czy lepiej postawić na deskę w wyższej półki, lekką, wytrzymałą, czy może na początek wystarczy tańszy model? Wybór nie jest łatwy, dlatego jeśli nie masz pewności, możesz się zdecydować komfortowe rozwiązanie pośrednie. Skorzystaj z naszej wypożyczalni SUP Decathlon Rent. Oferujemy wynajem sprzętu wodnego w wielu miastach na terenie Polski, jak Warszawa, Katowice, Poznań, Olsztyn, Trójmiasto oraz wielu innych.  Poznaj szczegóły naszej usługi i zacznij korzystać  z Decathlon Rent już dziś!</p>
              
              <h2>Wypożyczalnia desek SUP w Warszawie - sprawdź ofertę i warunki rezerwacji w Decathlon Rent</h2>
              <p>Decathlon Rent w Warszawie oferuje szeroką ofertę produktów. Możesz wypożyczać sprzęt do sportów wodnych, i odbierać go we wskazanym przez siebie oddziale. Cały proces rezerwacji jest bardzo prosty i odbywa się online. Wystarczy że wyszukasz model deski, która Cię interesuje i zaznaczysz ramy czasowe wypożyczenia, następnie wystarczy opłacić swoje zamówienie. Odbioru deski dokonujesz we wskazanej przez siebie placówce na terenie miasta, zwrot odbywa się w tym samym miejscu.</p>
              <p>Wypożyczalnia stand up paddle w Warszawie w postaci usług oferowanych przez Decathlon Rent to komfortowe i kompleksowe rozwiązanie. Atrakcyjne ceny za odbiór wiosła w zestawie z deską łączą się z opcją rezerwacji oraz płatności przez internet. W razie potrzeby można skorzystać z doradztwa ekspertów dostępnych zawsze na miejscu, w sklepie Decathlon. To opcja szczególnie przydatna dla początkujących, którzy zastanawiają się nad tym, z jakim typem technologii wykonania desek rozpocząć swoją przygodę ze stand up paddle. Zapoznaj się z cennikiem wypożyczenia w Warszawie!</p>

              <h2>Decathlon Rent Katowice - wypożycz deskę Stand Up Paddle na Górnym Śląsku</h2>
              <p>Pływanie na SUP cieszy się dużym zainteresowanie nie tylko wśród amatorów wakacyjnych sportów wodnych. Jest to także świetny sposób na trening i wzmocnienie całego ciała. Ponieważ na desce obierana jest pozycja stojąca warto rozważyć założenie butów do wody, które są antypoślizgowe i pomogą w utrzymaniu stabilnej pozycji. Deski uznanych marek, które znajdują się w naszej ofercie dostępne są w różnych rozmiarach. Jeśli nie jesteś pewny swojego wyboru, skontaktuj się z nami i poproś o fachowe doradztwo. Jeśli więc szukasz dobrej jakości pompowanych desek SUP na wynajem, zapraszamy do Decathlon Rent w Katowicach! </p>
              <p>Wybierając Katowice, wypożyczalnia SUP'ów Decathlon Rent będzie dokładnie tak samo komfortowym wyborem jak w stolicy kraju. Obowiązują tu te same możliwości związane z rezerwacją internetową, wygodną płatnością szybkimi przelewami online oraz sporym wyborem sprzętu. Ze wszystkimi parametrami można zapoznać się na stronie, a o szczegóły techniczne warto zapytać eksperta Decathlon. Dodatkowo trzeba pamiętać, iż dłuższy czas wypożyczenia powoduje obniżenie ceny to idealne rozwiązanie dla tych, którzy wybierają się w różne zakątki Polski lub Europy. Sprawdź cennik wypożyczenia w Katowicach!</p>

              <h2>Wypożyczalnia SUP Decathlon Rent Trójmiasto - poznaj ofertę i cennik</h2>
              <p>Gdańsk, Gdynia i Sopot to bardzo popularne miasta pod kątem uprawiania sportów wodnych, którymi zainteresowani są nie tylko mieszkańcy, ale i przyjezdni. <a href="https://rent.decathlon.pl/wypozyczalnia-kajakow">Wypożyczalnia kajaków</a> i wypożyczalnia Sup Decathlon Rent oferują szeroką gamę produktów, które z pewnością przypadną do gustu wszystkim użytkownikom. Postaw zatem na sprawdzony sprzęt, który jest regularnie serwisowany, wybierz modele najlepszych marek oraz niezbędne akcesoria i ciesz się swoim hobby do woli.</p>
              <p>W Decathlon Rent w Trójmieście możesz wynająć różne rodzaje desek paddle board, są one dostępne w wielu rozmiarach, więc z pewnością znajdziesz produkt najlepiej dopasowany do Twoich potrzeb. Zapraszamy do korzystania z naszych usług - proces zamawiania sprzętu jest prosty i intuicyjny. Całość odbywa się online, jedynie po odbiór musisz stawić się osobiście. Skorzystaj z opcji wynajmu zwłaszcza, jeśli nosisz się z zamiarem kupna deski na własność - testuj różne modele i wybierz najlepszy z nich. Zapraszamy do wypożyczalni desek SUP Decathlon Rent w Trójmieście!</p>

              <h2>Wypożyczalnia desek SUP w Olsztynie - przyjdź do Decathlon Rent i spróbuj swoich sił w paddle board</h2>
              <p>Pływanie na desce SUP przynosi wiele korzyści - pozwala przyjemnie spędzać czas, podziwiać piękne okolice i dbać o formę. Tego typu hobby działa zdecydowanie uspokajająco i regenerująco. Dzięki wypożyczalni desek SUP Decathlon Rent w Olsztynie masz możliwość spełniać się w tym sporcie bez konieczności kupowania sprzętu na własność (zwłaszcza jeśli dopiero zaczynasz swoją przygodę lub nie masz miejsca do przechowywania). </p>
              <p> Przy okazji warto wziąć pod uwagę to, iż deska świetnie łączy się z kempingiem i nocowaniem w namiocie. Oczywiście cały dodatkowy sprzęt również można skompletować w Decathlon Rent. Olsztyn i tutejsza wypożyczalnia SUP'ów jest zatem rozwiązaniem kompleksowym. Za pomocą kilku kliknięć możesz wynająć cały ekwipunek, jakiego potrzebujesz (sprawdź ofertę naszej wypożyczalni namiotów ). Odkryj paddleboarding w Olsztynie.</p>
              
              <h2>Decathlon Rent Poznań - wynajem sprzętu wodnego uznanych marek</h2>
              <p>Planujesz wakacyjny wyjazd nad jezioro lub na południe Europy? Skorzystaj z oferty Decathlon Rent w Poznaniu. Nasza wypożyczalnia desek pomoże Ci przygotować się do udanego wyjazdu zarówno na weekend, jak i na dłuższy czas.</p>
              <p>Cały proces rezerwacyjny w Decathlon Rent odbywa się w pełni online dzięki czemu oszczędzasz swój czas - jest prosty i intuicyjny. Wystarczy że znajdziesz model produktu, na którym Ci zależy, zaznaczysz interesujące Cię daty i dokonasz płatności. Odbiór i zwrot zamówienia odbywa się we wskazanej przez Ciebie placówce, a jeśli masz jakiekolwiek pytania, możesz zwrócić się do naszych ekspertów. Skorzystanie z usług wypożyczalni Decathlon Rent w Poznaniu to także świetny pomysł na test sprzętu przed jego zakupem - warto o tym pamiętać, zanim nabędzie się deskę na własność. Chcesz dowiedzieć się więcej? Zapraszamy do kontaktu!</p>
              
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export default SeoPage;
