import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import { Page } from '../../components';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink
} from '../../components';

import css from './SeoPage.css';

const SeoPage = () => {
  const { siteTwitterHandle, siteFacebookPage } = config;

  // prettier-ignore
  return (
    <Page
      title="Wypożyczalnia sprzętu fitness - Decathlon Rent"
      description="Wypożyczalnia sprzętu fitness Decathlon Rent oferuje wiele profesjonalnych urządzeń do ćwiczeń. Sprawdź ofertę i wynajmij bieżnię lub rowerek stacjonarny już dziś!"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Wypożyczalnia sprzętu fitness Decathlon Rent oferuje wiele profesjonalnych urządzeń do ćwiczeń. Sprawdź ofertę i wynajmij bieżnię lub rowerek stacjonarny już dziś!',
        name: 'Wypożyczalnia sprzętu fitness - Decathlon Rent',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          {/* <TopbarContainer /> */}
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <img src="https://prod-rent-backend-a9sd29me0v9sv.s3.eu-west-1.amazonaws.com/prod/stat-img/logo2.png" className={css.logo} />
          <h1 className={css.pageTitle}>Wypożyczalnia sprzętu fitness - Decathlon Rent</h1>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    'keywords=fitness',
                }}
                className={css.searchLink}
              >
                Sprawdź
              </NamedLink>
              <p>Zdrowy tryb życia, to połączenie codziennej aktywności fizycznej i odpowiednio skomponowanej diety. Aby zadbać o swoją kondycję wcale nie musisz być stałym bywalcem na siłowni, możesz także ćwiczyć w domu. Ważna jest systematyka, to dzięki niej masz szansę poprawić swój wygląd i formę, a także zadbać o zdrowie. Coraz bardziej popularne staje się urządzanie siłowni w domu czy w mieszkaniu. W zależności od tego, ile miejsca masz do dyspozycji, bez trudu dobierzesz sprzęty do treningów. Jeśli jednak nie masz możliwości zakupu zakupu bieżni, wioślarza czy rowerka stacjonarnego, albo właśnie masz taki zamiar, ale nie wiesz jaki model wybrać, skorzystaj z usług wypożyczalni sprzętu fitness Decathlon Rent. </p>
              <p>Nasza oferta jest dedykowana do wszystkich sympatyków sportu i przede wszystkim jest łatwo dostępna. Z usług wypożyczalni Decathlon Rent skorzystasz nie tylko w dużych miastach, jak: Warszawa, Gdańsk, Poznań czy Wrocław, ale i w wielu mniejszych miejscowościach. Asortyment produktów jest stale rozbudowywany, dzięki temu mamy możliwość zaproponować wynajem sprzętu fitness, który jest bardzo zróżnicowany jeśli chodzi o charakterystykę modeli, marki i pozostaje dostępny dla użytkowników w atrakcyjnych cenach. Zapraszamy do korzystania z naszych usług!</p>
              <h2>Wypożyczalnia sprzętu fitness - propozycja dla mieszkańców Warszawy</h2>
              <p>Warszawa, jak każde duże miasto daje wiele możliwości mieszkańcom. Sympatycy aktywności fizycznych mogą korzystać z oferty wielu siłowni i klubów sportowych. Co jednak w sytuacji, gdy nie masz czasu by dojeżdżać do siłowni lub ciężko pogodzić Ci treningi poza domem z codziennymi obowiązkami?Możliwości jest kilka. Jedną z nich jest domowa siłownia, która daje Ci możliwość ćwiczenia o dowolnych porach dnia, kiedy tylko masz na to czas i ochotę.</p>
              <p>Systematyczne ćwiczenia mogą wnieść dużo dobrego dla Twojej formy i organizmu, jeśli planujesz przygotować swoje miejsce do ćwiczeń w zaciszu domowym, powinieneś zadbać o dobór odpowiednich urządzeń. Ich wybór na rynku jest duży, co czasem wcale nie pomaga w wyborze. Jeśli planujesz zakup bieżni, orbitreka, ławeczki do ćwiczeń lub innego sprzętu, ale nie wiesz czy model jaki Cię interesuje będzie odpowiedni, skorzystaj z wynajmu sprzętu do fitnessu. Dzięki usłudze Decathlon Rent możesz wypożyczyć orbitrek, bieżnię lub inne urządzenie do ćwiczeń i przetestować je w domu. Umożliwiamy wynajem sprzętu na 1 dzień, tydzień i dłużej - tyle ile będziesz potrzebował. Za pośrednictwem naszej strony online możesz w prosty sposób wyszukać urzadzenie, które Cię interesuje, określić daty wynajmu i zarezerwować bez wychodzenia z domu. Możesz odebrać od nas sprzęt lub skorzystać z możliwości dostawy - wtedy to my wniesiemy i zmontujemy wybrane urządzenie u Ciebie w domu.</p>
              <p>Oferta Decathlon Rent jest stworzona z myślą o zapracowanych osobach, którym trudno jest wygospodarować czas na to, aby uczęszczać na siłownię, Wynajem sprzętu treningowego do domu będzie doskonałą alternatywą.</p>
              <h2>Wypożyczalnia orbitreków i bieżni - dlaczego warto wypożyczać sprzęt</h2>
              <p>Orbitrek to urządzenie, które ma wiele zalet, ale najważniejszą spośród nich jest to, że treningi na nim pozwalają na niebywale szybką redukcję tkanki tłuszczowej. Zaledwie jedna godzina ćwiczeń umożliwia spalenie od 700 do 850 kcal, w zależności od intensywności wykonywanych ruchów. Dodatkowo wszelka aktywność fizyczna na orbitreku to również: pozytywny wpływ na poprawę kondycji, usprawnienie pracy układu krążenia, dotlenienie organizmu, ogólne polepszenie się samopoczucia. Efekty regularnych ćwiczeń na orbitreku mogą prawdziwie zaskoczyć. Powszechnie uznaje się, że trenażer eliptyczny to przykład jednego z najbardziej wszechstronnych sprzętów do fitnessu, jakie są dostępne na obecnym rynku. Bieżnia jest kolejnym istotnym elementem naszej oferty, pozwala na efektywne treningi cardio bez konieczności wychodzenia z domu. Regularne biegi na bieżni mogą znacząco poprawić Twoją kondycję i zdrowie sercowo-naczyniowe.  Warto wspomnieć, że sprzęty fitness często stosowane są w celach rehabilitacyjnych przy wychodzeniu z kontuzji lub po urazach. Jest to zatem doskonałe połączenie - sprzęt fitness i rehabilitacyjny w jednym. </p>
              <p>Zachęcamy do skorzystania z oferty Decathlon Rent. Z nami wynajem bieżni czy rowerka stacjonarnego jest banalnie prosty i wygodny. Wypożycz i przetestuj, przekonaj się, że to dobry wybór!</p>
              <h2>Wynajem rowerów treningowych stacjonarnych w Katowicach</h2>
              <p>Bycie aktywnym fizycznie jest bardzo ważnym elementem codzienności. Czasami jednak trudno jest wygospodarować czas na to, aby wyjść do klubu fitness lub wyjść na rower. Sympatycy wycieczek rowerowych chętnie zapełniają w ten sposób swój czas wolny. Jednak sezon na jazdę na rowerze nie trwa cały rok. Zimą, gdy na ulicach jest lód i śnieg lepiej zrezygnować z tej aktywności, dla własnego bezpieczeństwa. W okresie zimowym świetną alternatywą będzie rowerek treningowy. Wypożyczalnia Decathlon Rent w Katowicach i innych miastach na terenie Polski umożliwia wynajem różnego rodzaju rowerków stacjonarnych, które bez trudu dopasujesz do swoich wymagań. Jeśli lubisz trenować na rowerze, może Cię również zainteresować oferta <a href="https://rent.decathlon.pl/wypozyczalnia-trenazerow-rowerowych">wynajmu trenażerów</a>,</p>
              <p>Wszystkie oferowane przez nas sprzęty na wynajem są regularnie serwisowane przez wykwalifikowanych pracowników, możesz mieć zatem pewność, że otrzymasz produkty najwyższej jakości. Wypożyczając urządzenia do ćwiczeń za pośrednictwem Decathlon Rent online, możesz wskazać sklep w dogodnej dla Ciebie lokalizacji, w której chciałbyś go odebrać. Oferujemy także możliwość dostarczenia go pod wskazany adres, dzięki temu zaoszczędzisz swój czas. </p>
              <p>Wynajem rowerów stacjonarnych w takim przypadku wydaje się doskonałym pomysłem! Jak widać, lista pozytywów jest dość długa, a tak naprawdę bez trudu można by było dodać do niej jeszcze kilka punktów. Jeśli jesteś z Katowic lub okolic tego miasta, to możesz przetestować jeden z rowerków stacjonarnych i wypożyczyć sprzęt do ćwiczeń fitness w ramach usługi Decathlon Rent. Wypożyczaj produkty najwyższej jakości, dostosowane do wieku i możliwości fizycznych użytkowników i ciesz się możliwością treningów bez zobowiązań i martwienia się o formalności.</p>
              <h2>Wynajem wioślarzy magnetycznych w Decathlon Rent w Gdańsku</h2>
              <p>Jesteś zwolennikiem ćwiczeń na ergometrze? Dzięki temu urządzeniu wioślarze mają szansę utrzymać dobrą kondycję przez cały rok po zakończonym sezonie. Podczas korzystania z tego urządzenia wprawiamy w ruch większość partii mięśni. Są to przede wszystkim mięśnie brzucha, rąk, nóg oraz barków. Wioślarz jest w stanie poruszyć wszystkie wymienione mięśnie jednocześnie, co sprawia, że można go z powodzeniem wykorzystywać nawet w bardzo złożonym cyklu treningowym. Wioślarze nie należą do najtańszych urządzeń, a na rynku stale pojawiają się nowe, bardziej zaawansowane modele, które pewnie chciałbyś sprawdzić. Dzięki usłudze wypożyczenia sprzętu fitness Decathlon Rent, możesz się cieszyć treningami na urządzeniu bez konieczności jego zakupu. Wioślarze magnetyczne są możliwe do wynajmu na terenie całej Polski. W Gdańsku znajdziesz duży wybór różnorodnych modeli, które cieszą się tam zdecydowanie dużym zainteresowaniem. Dla sympatyków wioślarstwa jest to wręcz idealne rozwiązanie - w sezonie mogą trenować na świeżym powietrzu, a poza sezonem - w domowym zaciszu.</p>
              <h2>Sprzęt fitness wypożyczalnia we Wrocławiu</h2>
              <p>Wypożyczalnia bieżni, ławeczek do wyciskania i rowerów stacjonarnych znajduje się również we Wrocławiu. Okolice Wrocławia zachęcają do wycieczek rowerowych, które umożliwiają dbanie o kondycję, a zarazem do podziwiania krajobrazów. Poza sezonem, warto rozważyć inne alternatywy, które pozwolą Ci zachować dobrą formę również w zimie. W Decathlon Rent wypożyczysz bieżnie, rowery stacjonarne i inne akcesoria, które mogą być wykorzystywane także jako sprzęt rehabilitacyjny po urazach i kontuzjach.  Zadbaj o swoją kondycję przez cały rok i ciesz się z możliwości uprawiania swoich ulubionych aktywności sportowych w zaciszu domowym.</p>
              <h2>Wynajem sprzętu sportowego fitness - wypożycz i przetestuj zanim kupisz sprzęt na własność</h2>
              <p><a href="https://rent.decathlon.pl/wypozyczalnia-sprzetu-sportowego">Wypożyczalnia sprzętu sportowego</a> to rozwiązanie, które jest dobre pod wieloma względami. U nas wynajmiesz sprzęt sportowy, który pozwoli Ci być aktywnym przez cały rok. Oferujemy możliwość <a href="https://rent.decathlon.pl/wypozyczalnia-rowerow">wypożyczenia rowerów</a>, znajdziesz także duży wybór <a href="https://rent.decathlon.pl/wypozyczalnia-sprzetu-turystycznego">namiotów do wynajęcia</a>, a w sezonie letnim dużą popularnością cieszą się <a href="https://rent.decathlon.pl/wypozyczalnia-desek-sup">wypożyczalnie SUP</a> i <a href="https://rent.decathlon.pl/wypozyczalnia-kajakow">kajaków</a>. </p>
              <p> Nasza usługa jest dostępna w wielu polskich miastach. Można z niej skorzystać m.in. w Warszawie, Poznaniu, Gdańsku, Katowicach oraz we Wrocławiu. Wykwalifikowany zespół pracowników w sieci Decathlon Rent chętnie pomoże dobrać sprzęt w taki sposób, aby miał on szansę sprostać indywidualnym oczekiwaniom każdego Klienta. Zapraszamy do zapoznania się z ofertą naszej wypożyczalni sprzętu fitness!</p>

            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export default SeoPage;

