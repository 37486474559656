import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import { Page } from '../../components';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink
} from '../../components';

import css from './SeoPage.css';

const SeoPage = () => {
  const { siteTwitterHandle, siteFacebookPage } = config;

  // prettier-ignore
  return (
    <Page
      title="Wypożyczalnia nart i sprzętów zimowych | Decathlon Rent"
      description="Skorzystaj z usług wypożyczalni nart i sprzętów zimowych Decathlon Rent. Wynajmij sprzęt narciarski m. in. w Warszawie, Gdańsku, Krakowie, Katowicach, Wrocławiu czy Poznaniu!"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: `Skorzystaj z usług wypożyczalni nart i sprzętów zimowych Decathlon Rent. Wynajmij sprzęt narciarski m. in. w Warszawie, Gdańsku, Krakowie, Katowicach, Wrocławiu czy Poznaniu!`,
        name: 'Wypożyczalnia nart i sprzętów zimowych | Decathlon Rent',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          {/* <TopbarContainer /> */}
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <img src="https://kgcodego.b-cdn.net/rent-logo.png" className={css.logo} />
          <h1 className={css.pageTitle}>Decathlon Rent - wypożyczalnia nart i pozostałego sprzętu narciarskiego na terenie całej Polski</h1>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    'address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&keywords=narty',
                }}
                className={css.searchLink}
              >
                Sprawdź
              </NamedLink>
              <p>Zastanawiasz się nad zakupem sprzętu do uprawiania sportów zimowych, ale nie wiesz jaki
                powinieneś kupić i czy faktycznie będziesz go używać? Rozwiązaniem jest skorzystanie ze
                sprawdzonej wypożyczalni sprzętu narciarskiego i snowboardowego oraz innych niezbędnych
                akcesoriów i wyposażenia. Decathlon Rent ma świetną ofertę skierowaną właśnie do Ciebie.
                Zapewniamy wygodny dostęp do szerokiego asortymentu sprzętowego. Możesz wypożyczać to,
                co Cię interesuje wygodnie, online, a jeśli potrzebujesz wsparcia doradcy, wystarczy że
                skontaktujesz się z nami. Jesteśmy dostępni na terenie całej Polski. Nasze oddziały działają w
                dużych miastach, m.in.: Warszawa, Gdańsk, Poznań, Wrocław, Katowice czy Kraków oraz w
                wielu innych, mniejszych miejscowościach. W asortymencie Decathlon Rent posiadamy na
                wynajem <a href="https://rent.decathlon.pl/kategoria/wypozyczalnia-nart-zjazdowych/">narty
                  zjazdowe</a>, <a href="https://rent.decathlon.pl/kategoria/wypozyczalnia-nart-
biegowych/">narty biegowe</a>, <a href="https://rent.decathlon.pl/kategoria/wypozyczalnia-
desek-snowboard/">deski snowboardowe</a>, <a
                  href="https://rent.decathlon.pl/kategoria/wypozyczalnia-rakow-zimowych/">raki i raczki</a>, 
                <a href="https://rent.decathlon.pl/kategoria/wypozyczalnia-rakiet-snieznych/">rakiety
                  śnieżne</a>, <a href="https://rent.decathlon.pl/kategoria/wypozyczalnia-czekanow/">czekany
                </a> i <a href="https://rent.decathlon.pl/kategoria/wypozyczalnia-sanek/">sanki dla
                  dzieci.</a></p>
              <p>Korzystając z naszych usług masz pewność, że sprzęt którego wynajęciem jesteś
                zainteresowany jest najwyższej jakości i odpowiednio przygotowany do sezonu. Wszystkie
                produkty są objęte serwisem, w którym sprawdzamy stan całego zimowego ekwipunku, a w
                razie potrzeby wymieniamy poszczególne elementy na nowe. Sprawdź cennik i dostępność
                poszczególnych modeli nart, poznaj zasady rezerwacji sprzętu i dowiedz się co, oferujemy w
                ramach profesjonalnych usług Decathlon Rent.</p>
              <h2>Wypożyczalnia sprzętu narciarskiego w Warszawie</h2>
              <p>Szczególnie dla początkujących lub okazjonalnych narciarzy zakup pełnego osprzętu jest
                dużym wydatkiem. Sprzęt, buty narciarskie, wiązania, kaski z goglami czy kijki zajmują sporo

                miejsca i mogą stanowić problem w przechowywaniu. Dla osób nie dysponujących wolną
                przestrzenią do sezonowego przechowywania całego zimowego wyposażenia, skorzystanie z
                możliwości wynajmu sprawdzonego ekwipunku będzie najlepszym rozwiązaniem. Dlaczego
                warto skorzystać z wypożyczalni sprzętu zimowego w Warszawie lub innym dużym mieście,
                zamiast bezpośrednio przy górskim stoku? Decydujące mogą być w tym przypadku trzy rzeczy -
                cena, jakość wynajmowanego sprzętu i dostępność modeli, na których nam zależy.</p>
              <p>Decathlon Rent to wypożyczalnia nart renomowanych marek w Warszawie, która oferuje
                możliwość wypożyczenia sprzętu sportowego online wraz z pełnym doradztwem.  Dokładamy
                wszelkich starań, by zapewnić Klientom duży wybór wysokiej klasy asortymentu. Profesjonalny
                serwis zajmuje się ostrzeniem nart, wymianą części, konserwacją i smarowaniem nart i desek
                snowboardowych. </p>
              <h2>Wypożyczalnia nart i snowboardów we Wrocławiu</h2>
              <p>Stolica Dolnego Śląska to idealne miejsce pod wyjazd na narty. W pobliżu znajduje się
                mnóstwo świetnie przygotowanych stoków i nowoczesnych wyciągów. Warto wymienić
                chociażby te w Zieleńcu, Szklarskiej Porębie i Karpaczu, z którymi infrastrukturą drogową i
                komunikacją autobusową doskonale połączony jest Wrocław.</p>
              <p>Wypożyczalnia nart Decathlon Rent we Wrocławiu oferuje duży wybór wysokiej jakości
                sprzętu i akcesoriów dla dzieci i dorosłych, które przydadzą się nie tylko na stoku, ale i w trakcie
                górskich wycieczek. Zapraszamy do zapoznania się z ofertą,  warunkami wynajmu i atrakcyjnym
                cennikiem. U nas zarówno początkujący jak i zaawansowani narciarze bez trudu znajdą akcesoria
                renomowanych marek, które najbardziej im odpowiadają, a w razie potrzeby nasi profesjonalni
                doradcy pomogą wybrać odpowiedni sprzęt snowboardowy czy akcesoria jakich
                potrzebujesz.</p>
              <p>Nie posiadasz własnego sprzętu, a chciałbyś cieszyć się zimowym szaleństwem na stoku?
                Teraz to nie problem. Skorzystaj z możliwości wypożyczenia sprzętu narciarskiego, gogli i kijów.
                Oferujemy szeroki wybór asortymentu dla osób, które dopiero stawiają pierwsze kroki w
                zimowych aktywnościach, jak i dla tych bardziej doświadczonych. Poznaj także nasze usługi
                związane z serwisem wyposażenia sportowego, dzięki którym możemy zagwarantować Ci
                niezawodność i solidność wynajętego ekwipunku. Skorzystaj z usług Decathlon Rent we
                Wrocławiu!</p>
              <h2>Decathlon Rent Katowice - wypożycz narty i inne akcesoria i ciesz się zimowym
                szaleństwem</h2>
              <p>Wypożyczalnia nart Decathlon Rent w Katowicach oferuje nie tylko szeroki wybór sprzętu,
                ale i obsługę mającą ogromne doświadczenie w sportach zimowych. Dzięki temu w dogodnych
                warunkach możesz liczyć na pomoc w doborze wyposażenia, zarówno dla profesjonalistów, jak i
                osób początkujących. Katowicka wypożyczalnia sprzętu narciarskiego to też gwarancja

                atrakcyjnych cen wynajmowanych akcesoriów, dzięki czemu pomysł weekendowego
                odpoczynku na stoku pozostanie w zasięgu twojego portfela. </p>
              <h2>Wynajem sprzętu narciarskiego i desek snowboardowych w Poznaniu - sprawdź
                ofertę</h2>
              <p>Czy mieszkańcy Poznania, aby móc pojeździć na nartach, są skazani na długą podróż w
                Sudety, Tatry, Beskidy lub nawet Alpy? Oczywiście chcąc zaznać górskiej atmosfery, to jest
                swego rodzaju konieczność, ale białe szaleństwo można uprawiać nawet na miejscu, przy
                Jeziorze Maltańskim!</p>
              <p>Decathlon Rent w Poznaniu oferuje możliwość wypożyczenia desek snowboardowych oraz
                szeroki wybór nart i innych akcesoriów, dzięki którym będziesz mógł cieszyć sie zimowymi
                aktywnościami, kiedy tylko zechcesz. Taki czasowy wynajem to bardzo wygodne rozwiązanie -
                sprzęt rezerwujesz online, podając zakres dat, odbierasz go w najbliższym oddziale i możesz
                cieszyć się swoją ulubioną dyscypliną sportową. </p>
              <p>Wypożyczalnia nart w Poznaniu to również świetne miejsce na pierwszy kontakt z
                określonym rodzajem osprzętu. Przed właściwym zakupem można go wypróbować, płacąc
                jedynie kwotę za wypożyczenie. Dzięki temu skorzystanie z usług wypożyczalni może okazać się
                usługą przydatną nie tylko dla amatorów sportów zimowych, ale i prawdziwych zapaleńców!
                Zapraszamy do naszego oddziału Decathlon Rent w Poznaniu, tam już dziś czeka na ciebie sztab
                doradców gotowych do pomocy.</p>
              <h2>Gdańsk i Gdynia - wynajmij sprzęt zimowy w Trójmieście</h2>
              <p>Wypożyczalnia nart w Gdańsku i w Gdyni to świetne rozwiązanie dla osób, które chcą
                uprawiać zimowe sporty bez konieczności zakupu sprzętu. </p>
              <p>Wybierając naszą ofertę, klient otrzymuje gwarancję wypożyczenia sprzetu najwyższej
                jakości, uprzednio sprawdzonego w naszym profesjonalnym serwisie. Sprzęt sportowy to nasza
                pasja - przez cały rok, również zimą. Prowadząc sprzedaż, mamy dostęp do najnowszych
                rozwiązań. Również nasza wypożyczalnia nart w Gdyni i Gdańsku korzysta z tego udogodnienia -
                możemy zaoferować bogaty wybór, atrakcyjne ceny i dostęp do zaawansowanych
                technologicznie, współczesnych rozwiązań.</p>
              <h2>Wypożyczaj zimowy sprzęt sportowy z Decathlon Rent - poznaj zalety i cennik</h2>
              <p>Zapraszamy do naszej wypożyczalni sprzetu zimowego Decathlon Rent. Poza szeroką ofertą,
                najnowszymi modelami nart i innych akcesoriów, atrakcyjnymi cenami i serwisem nart
                oferujemy wiele korzysci. Poznaj wszystkie zalety korzystania z usług Decathlon Rent w Twoim
                mieście!</p>
              <ul>

                <li>Dostępność w największych miastach w Polsce (Warszawa, Wrocław, Katowice, Poznań,
                  Kraków, Gdańsk i in.) oraz w wielu innych miejscach można skorzystać z naszej oferty.</li>
                <li>Możliwość komfortowego przetestowania nowoczesnego sprzętu. Zasoby Decathlon Rent to
                  przede wszystkim nowe narty i osprzęt, które są dostępne również do zakupu na sklepowych
                  półkach.</li>
                <li>Tania wypożyczalnia nart? Z Decathlon Rent wypożyczenie się opłaca! Nie warto przepłacać
                  w miejscowościach wypoczynkowych, lepiej od razu skierować się po atrakcyjną ofertę do
                  nas!</li>
                <li>Fachowa obsługa, znająca się na sprzęcie. Nasi pracownicy to najczęściej osoby same
                  uprawiające różnego rodzaju sporty, także te zimowe.</li>
                <li>Rezerwacja online! Możesz przejrzeć ofertę na stronie internetowej, zarezerwować i opłacić
                  zamówienie przelewem, a po odbiór zgłosić się do wybranego sklepu.</li>
                <li>Wygodny odbiór i zwrot — jasne zasady. Po więcej informacji zapraszamy do naszego
                  FAQ.</li>
              </ul>
              <p>Najważniejsze wyznaczniki, którymi kieruje się nasza wypożyczalnia nart, to możliwość
                wyboru. Zarówno w kontekście sposobu zamówienia - online lub na miejscu - jak i w odniesieniu
                do mnogości dostępnych sprzętów. Dzięki temu klient może samemu zadecydować, jak
                przeprowadzi całą transakcję i czy skorzysta z dodatkowego wsparcia naszych pracowników. W
                każdym wypadku ma gwarancję atrakcyjnej ceny, niezależnie czy dokonuje wypożyczenia w
                Warszawie, czy w Katowicach czy w innym mieście.</p>
              <p></p>
              <p>Zapraszamy do naszych oddziałów Decathlon Rent i na stronę internetową, a w przypadku
                pytań - z służymy wiedzą i profesjonalną pomocą!</p>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export default SeoPage;
