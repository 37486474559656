import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import { Page } from '../../components';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink
} from '../../components';

import css from './SeoPage.css';

const SeoPage = () => {
  const { siteTwitterHandle, siteFacebookPage } = config;

  // prettier-ignore
  return (
    <Page
      title="Wypożyczalnia sprzętu turystycznego - Decathlon Rent"
      description="Wypożyczalnia sprzętu turystycznego Decathlon Rent oferuje wynajem namiotów, nosidełek turystycznych, plecaków i sprzętu wspinaczkowego. Sprawdź szczegóły!"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Wypożyczalnia sprzętu turystycznego Decathlon Rent oferuje wynajem namiotów, nosidełek turystycznych, plecaków i sprzętu wspinaczkowego. Sprawdź szczegóły!',
        name: 'Wypożyczalnia sprzętu turystycznego - Decathlon Rent',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          {/* <TopbarContainer /> */}
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <img src="https://prod-rent-backend-a9sd29me0v9sv.s3.eu-west-1.amazonaws.com/prod/stat-img/logo2.png" className={css.logo}/>
          <h1 className={css.pageTitle}>Decathlon Rent - wypożyczalnia sprzętu turystycznego</h1>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    'address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&businessCategory=camping_tent&category=camping&exact=true&keywords=Namioty%20kempingowe',
                }}
                className={css.searchLink}
              >
                Sprawdź
              </NamedLink>
              <p>Turystyka cieszy sie niesłabnącym zainteresowaniem wśród wielu osób. Jej popularność wynika przede wszystkim z faktu, że to sport praktycznie dla każdego. Bez trudu można dostosować ją do swojego wieku czy posiadanej kondycji. Aby móc w pełni cieszyć się z uprawiania turystyki i zapewnić sobie maksymalne bezpieczeństwo należy zadbać o odpowiedni sprzęt. Nie oznacza to wcale, że musisz go od razu kupować, zwłaszcza jeśli brakuje Ci miejsca do jego przechowywania lub z powodów finansowych. </p>
              <p>Nasza wypożyczalnia sprzętu turystycznego Decathlon Rent to idealne rozwiązanie dla wielu osób. Oddziały Decathlon Rent znajdują się nie tylko w dużych miastach, ale też wielu mniejszych miejscowościach na terenie całej Polski. Oferujemy wynajem sprzętu turystycznego najwyższej jakości, który nie tylko pochodzi od sprawdzonych producentów, ale też podlega regularnemu serwisowi, co daje gwarancję niezawodności i zadowolenia w trakcie jego użytkowania. Poznaj szczegóły dotyczące wynajmu ekwipunku turystycznego i skorzystaj z wygodnej usługi!</p>
              
              <h2>Wypożyczalnia namiotów i akcesoriów turystycznych - wszystko czego potrzebujesz na kemping</h2>
              <p>Szukasz namiotu turystycznego, kempingowego lub na wyjazd na ryby? W Decathlon Rent wynajmiesz namiot, jakiego potrzebujesz -  dla 2-3 osób lub namiot rodzinny dla 4 lub większej ilości osób. Skorzystaj z naszych usług i<a href="https://rent.decathlon.pl/kategoria/wypozyczalnia-plecakow-turystycznych/"> wypożycz plecak turystyczny</a>, do którego zapakujesz wszelkie niezbędne rzeczy lub materac. <a href="https://rent.decathlon.pl/kategoria/wypozyczalnia-namiotow-turystycznych/">Wypożyczalnia namiotów</a> Decathlon Rent daje wiele możliwości, bez konieczności dokonywania zakupów! Wystarczy wejść na stronę Decathlon Rent, wyszukać odpowiedni sprzęt, zarezerwować i opłacić, a będzie on gotowy do odbioru w wybranym przez Ciebie oddziale, w preferowanym terminie.</p>
              <p>Wypożyczenie namiotów to świetne rozwiązanie dla osób podróżujących w pojedynkę lub w grupie. W asortymencie Decathlon Rent znajdziesz różnorodne modele namiotów najwyższej jakości, od sprawdzonych producentów. Bez trudu wybierzesz model najlepiej pasujący do Twoich aktualnych potrzeb. Popularnością cieszą się zarówno większe namioty kempingowe z dwiema sypialniami, jak i nieduże namioty turystyczne. Mozesz wybierać w zależności od tego, na czym aktualnie najbardziej Ci zależy. </p>
              <p>Niejednokrotnie zakup namiotu i pozostałego zestawu produktów przydatnych na kempingu, wiąże się z większym wydatkiem. Nie każdy klient ma możliwość i chęci by dokonać takiego zakupu, zwłaszcza jeżeli tego typu sprzętu potrzebujesz okazyjnie. Skorzystaj z usług Decathlon Rent i realizuj swoje plany wyjazdowe bez przeszkód. </p>
              
              <h2>Wynajem sprzętu do turystyki - wypożycz raki, raczki i czekan przed wyjściem na górskie szlaki</h2>
              <p>Zima to wymagająca pora roku dla wszystkich turystów wybierających się na górskie szlaki. Warto wtedy szczególnie zadbać o swoje bezpieczeństwo i przygotować się do każdego wyjścia na szlak w odpowiedni sposób. Nie zawsze jest tam bezpiecznie, dlatego każdy turysta, nawet bardzo doświadczony powinien stosować się do zaleceń TOPR. Przede wszystkim trzeba uwzględnić właściwy ubiór, pozwalający na utrzymanie prawidłowych warunków termicznych dla organizmu. W tym celu zapraszamy do sklepów Decathlon, gdzie w wyborze pomagają doświadczeni pracownicy. Nie każde akcesorium trzeba jednak kupować. Wynajmowanie sprzętów, z których potrzebujesz skorzystać raz na jakiś czas, to idealna alternatywa.</p>
              <p>Co istotne, transakcji dokonujesz wygodnie online. Wystarczy, że wybierzesz swój zimowy ekwipunek do turystyki, jak raki, raczki rakiety śnieżne lub czekan, zarezerwujesz dogodny termin i możesz odebrać wszystko w wybranej placówce. O ile czekan i raki to akcesoria dedykowane bardziej zaawansowanym szlakom i wykorzystywane do wspinaczki, to jednak wychodząc na górskie szlaki zimą każdy powinien zabrac ze sobą raczki, a na świezym śniegu rakiety śnieżne będą wręcz niezastąpione.</p>
              <p>Dzięki usługom Decathlon Rent możesz także przetestować sprzęt, który rozważasz do zakupu. Wybierac model, jaki Cię interesuje będziesz miał szansę przekonać się jak sprawdza się on w trakcie użytkowania i czy odpowiada Ci w 100 %. Zapraszamy do <a href="https://rent.decathlon.pl/kategoria/wypozyczalnia-czekanow/">wypożyczenia czekanów</a> i raczków.</p>
              
              <h2>Wypożyczalnia nosidełek turystycznych dla dzieci - bezpieczeństwo i komfort na każdej wycieczce w góry</h2>
              <p>Na szlaku często można spotkać rodziny z małymi dziećmi. Warto rozwijać pasję i zainteresowania tym sportem już od wczesnych lat, należy jednak pamiętać, że najmłodsi nie mają tyle sił co dorośli i szybciej sie męczą. Wybierając się na wycieczkę rowerem z dzieckiem można temu zaradzić korzystając z <a href="https://rent.decathlon.pl/kategoria/wypozyczalnia-fotelikow-rowerowych/">wypożyczenia fotelików rowerowych</a>, przyczepek lub wózków. W turystyce pieszej natomiast najlepiej sprawdzą sie nosidełka turystyczne dla dzieci.</p>
              <p>Tego rodzaju sprzęt jest nie tylko funkcjonalny, bo nosidełka w formie plecaka umozliwiają również zapakowanie drobnych rzeczy, ale przede wszystkim jest wygodny dla dziecka i noszącego je rodzica. Nosidła są łatwe w transporcie, ponieważ nie zajmują dużo miejsca w samochodzie. Bez trudu zapakujesz je do bagażnika samochodowego lub dachowego. W Decathlon Rent znajdziesz duży wybór nosideł, które idealnie sprawdzą się na dłuższe czy krótsze wyprawy. Wybierz model, który Cię interesuje i wynajmij online,</p>
              
              <h2>Wypożyczalnia sprzętu turystycznego - sprawdź ofertę w dużych miastach: Katowice, Poznań, Warszawa i Kraków</h2>
              <p>Dostęp do placówek Decathlon Rent w dużych miastach jest bardzo wygodny, a oferta produktowa - bogata. Każdy bez problemu znajdzie to czego mu potrzeba.  </p>
              <p> Wypożyczalnia sprzętu do turystyki w Poznaniu gwarantuje dostęp do wysokiej jakości akcesoriów w bardzo przystępnych cenach. Warto tę propozycję przemyśleć przed wyjazdem do strefy euro, w końcu z tego miasta szybko dojedziesz do Niemiec lub w Alpy.</p>
              <p>Kraków swego czasu fotografowany był z Tatrami w tle. Oczywiście góry nie znajdują się tuż za granicami miasta, ale wystarczą 2 godziny by dotrzeć do podnóży Tatr, gdzie można cieszyć się zapierającymy dech widokami i korzystać z wszelkich atrakcji, jakie ta okolica oferuje miłośnikom górskich aktywności. W wypożyczalni sprzętu turystycznego w Krakowie znajdziesz duży wybór akcesoriów na wycieczki po szlakach o różnym poziomie zaawansowania (m. in. plecaki, namioty, kijki i wiele innych). Sprawdzone i serwisowane produkty najlepszych marek wynajmiesz w atrakcyjnych cenach. </p>
              <p>Katowice także oferują dla swoich mieszkańców szeroki wybór produktów przydatnych do aktywności na świeżym powietrzu. Bliskość gór sprawia, że turystyka w tym regionie cieszy się ogromną popularnością. Poznaj dostępny asortyment w wypożyczalni ekwipunku górskiego i skorzystaj z mozliwości jakie daje Decathlon Rent w tym mieście już dziś!</p>
              <p>Warszawa żyje korporacyjnym, międzynarodowym tempem, charakterystycznym dla niemal wszystkich stolic. To oznacza, że każdy mieszkaniec prędzej czy później chce wyrwać się choćna chwilę, aby zaznać nieco spokoju i po prostu odpocząć. Wypożyczalnia namiotów, bliskość Mazur i dogodne połączenie drogowe - te trzy rzeczy z powodzeniem się łączą. Wszystkich z Warszawy i okolic, spragnionych kontaktu z naturą, przed wyjazdem zapraszamy do Decathlon Rent, gdzie można wyposażyć się w markowy sprzęt do turystyki.</p>
              
              <h2>Dlaczego warto skorzystać z oferty wypożyczalni sprzętu do turystyki Decathlon Rent?</h2>
              <p>Usługa Decathlon Rent to nie tylko wypożyczalnia namiotów, plecaków i pozostałego ekwipunku do turystyki, ale i kompleksowe rozwiązania dla początkującego turysty. Pełne zaopatrzenie się w sprzęt dla podróżnika może być dość obciążające finansowo. Kilkaset złotych za namiot, plecak, kijki do nordic walking, lodówka turystyczna i nosidło dla dziecka - to nie są małe koszty. W Tatrach niezbędny będzie także czekan i raczki. Wypożycz te wszystkie przedmioty razem, ponosząc minimalny koszt, który w Decathlonie zmniejsza się wraz z okresem wynajmu! Poznaj wszystkie zalety koszystania z usług Decathlon Rent:</p>
              <ul>
                <li>Duży wybór. Wypożyczalnia namiotów dysponuje wieloma różnymi modelami i wielkościami sprzętów, a do tego stale wprowadzane są kolejne akcesoria z bieżącej oferty.</li>
                <li>Oszczędzasz miejsce. Wypożyczalnia sprzętu turystycznego gwarantuje ci dostępność najwyższej jakości atrybutów podróżnika, które będą gotowe w sam raz na wyprawę. A przez resztę roku nie trzeba ich chować w kątach mieszkania!</li>
                <li>Możesz wszystko zrobić online! Wypożycz raczki i raki przed wyjazdem w Tatry, dokonując rezerwacji przez internet. Odbioru i zwrotu dokonujesz w sklepie, ale płatność i potwierdzenie dostępności załatwisz w dowolnej przeglądarce internetowej.</li>
                <li>Łatwe podróżowanie z dziećmi. <a href="https://rent.decathlon.pl/kategoria/wypozyczalnia-nosidelek-turystycznych/">Wypożyczalnia nosidełek</a> to rozwiązanie dedykowane dla świeżo upieczonych rodziców, dzięki któremu wyjście na szlak z pociechą stanie się bardzo komfortowe zarówno dla dziecka, jak i rodzica.</li>
              </ul>
              <p>Warto pamiętać, że  wypożyczalnia sprzętu turystycznego działa nie tylko w dużych miastach, jak Katowice i Warszawa, ale i w wielu mniejszych miejscowościach. Jeśli Twoich okolic nie ma jeszcze na mapie Decathlon Rent, to daj nam znać, że taka usługa przyda się w nowym regionie. Zapraszamy do odwiedzania naszych stron, gdzie regularnie pojawiają się informacje o rozwoju usługi.</p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export default SeoPage;
