import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import { Page } from '../../components';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink
} from '../../components';

import css from './SeoPage.css';

const SeoPage = () => {
  const { siteTwitterHandle, siteFacebookPage } = config;

  // prettier-ignore
  return (
    <Page
      title="Decathlon Rent- wypożyczalnia sprzętu sportowego w blisko Ciebie!"
      description="Sprawdź naszą ofertę w serwisie Decathlon Rent - wypożyczalni sprzętu turystycznego, rowerowego czy też wodnego."
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Sprawdź naszą ofertę w serwisie Decathlon Rent - wypożyczalni sprzętu turystycznego, rowerowego czy też wodnego.',
        name: 'Wypożyczalnia trenażerów rowerowych - Decathlon Rent',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          {/* <TopbarContainer /> */}
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <img src="https://prod-rent-backend-a9sd29me0v9sv.s3.eu-west-1.amazonaws.com/prod/stat-img/logo2.png" className={css.logo} />
          <h1 className={css.pageTitle}>Decathlon Rent - wypożyczalnia sprzętu sportowego</h1>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    'address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641',
                }}
                className={css.searchLink}
              >
                Sprawdź
              </NamedLink>
              <p>Interesuje Cię wynajęcie roweru, chciałbyś wypożyczyć trenażer lub sprzęt fitness? Decathlon Rent to wypożyczalnia sprzętu sportowego działająca na terenie całej Polski. Oferujemy różnorodne produkty, które pozwolą Ci uprawiać ulubione dyscypliny sportowe bez konieczności kupowania drogiego sprzętu.  Wypożyczalnia Decathlon Rent to także świetne rozwiązanie, gdy planujesz zakup i chcesz wcześniej przetestować wybrane modele, by zdecydować, który z nich odpowiada Ci najbardziej.</p>
              <p>Sprawdź dostępność produktów w oddziałach Decathlon Rent i renomowanych punktach partnerskich w Twoim mieście lub tam, gdzie wybierasz się np. na urlop. Ciesz się możliwością uprawiania przeróżnych dyscyplin bez ponoszenia dużych kosztów za wypożyczenie sprzętu. </p>
              <h2>Wynajem sprzętu sportowego w Twoim mieście - oferta Decathlon Rent</h2>
              <p>Sprawdź wypożyczalnie sprzętu i akcesoriów sportowych Decathlon Rent w wielu miastach i miejscowościach w Polsce i stań się jednym wielu amatorów aktywnego wypoczynku na świeżym powietrzu lub w domowym zaciszu. Dzięki naszej usłudze wypożyczysz produkty sportowe na tak długo, jak potrzebujesz (na jeden dzień, tydzień, a nawet miesiąc). Wysokiej jakości sprzęt sportowy, atrakcyjne ceny i bezpieczeństwo transakcji to jedne z najważniejszych zalet wynajmowania sprzętów treningowych w Decathlon Rent. Cały proces rezerwacji zrealizujesz online, bez wychodzenia z domu. </p>
              <p>Jeśli interesują Cię wycieczki rowerowe, turystyka górska, wyjazd na kemping lub sporty sezonowe - jazda na nartach czy sporty wodne - skorzystaj z naszej oferty. U nas każdy znajdzie to czego szuka, by rozwijać swoje sportowe pasje.  W Decathlon Rent oferujemy <a href="https://rent.decathlon.pl/wypozyczalnia-rowerow">wynajem rowerów</a>, <a href="https://rent.decathlon.pl/wypozyczalnia-desek-sup">wypożyczenie desek SUP</a> i kajaków,  sprzęt turystyczny i kempingowy (w tym możliwość wypożyczenia namiotów) oraz wynajem sprzętu fitness czy <a href="https://rent.decathlon.pl/wypozyczalnia-trenazerow-rowerowych">trenażerów</a>. Sprawdź szczegóły i skorzystaj z kompleksowych usług już dziś!</p>
              <h2>Czym jest wypożyczalnia sprzętu sportowego Decathlon Rent?</h2>
              <p>Decathlon Rent umożliwia wynajem produktów sportowych na terenie całej Polski. W każdym dużym mieście, jak: Katowice, Wrocław, Warszawa, Poznań, Olsztyn czy Trójmiasto, a także wielu mniejszych miejscowościach - sprawdź dostępność produktów katalogowych przeznaczonych do wypożyczenia i skorzystaj z okazji do uprawiania sportów gdziekolwiek jesteś lub się wybierasz. Sprawdź ofertę produktów na naszej stronie i wybierz te, które najbardziej Cię interesują. Teraz możesz wypożyczać swoje ulubione maszyny do ćwiczeń bez wychodzenia z domu. Proces zamówienia, uzupełniania danych i dokonywania płatności odbywa się online. Jest prosty i intuicyjny, dzięki czemu szybko dokonasz wszelkich formalności. Sprzęt odbierasz w dogodnej dla Ciebie lokalizacji lub zamawiasz go z dostawą pod wskazany adres. Gwarantujemy, że wszystkie urządzenia są regularnie sprawdzane i serwisowane, dzięki czemu otrzymujesz produkty zadbane, sprawne i wysokiej jakości. </p>
              <h2>Wypożyczalnia sprzętu fitness - jak wypożyczać w Decathlon Rent </h2>
              <p>Nasza oferta jest dedykowana dla każdego, a zwłaszcza osób, które chcą aktywnie spędzać czas i dbać o własne zdrowie i kondycję. <a href="https://rent.decathlon.pl/wypozyczalnia-sprzetu-fitness">Wypożyczalnia sprzętu fitness</a> oferuje wynajem wioślarzy, orbitreków, bieżni, rowerków stacjonarnych i innych urządzeń do treningów w domu. Już nie musisz chodzić na siłownię, by z nich korzystać. Wypożycz sprzęt fitness na dowolny okres z odbiorem w dogodnej lokalizacji! Wystarczy, że za pośrednictwem strony internetowej wybierzesz model urządzenia, które Cię interesuje i zakres dat. Kolejnym krokiem będzie zaznaczenie dogodnej lokalizacji odbioru i zwrotu oraz płatność. Nie pobieramy opłat rezerwacyjnych.</p>
              <p>Poznaj szczegóły i skorzystaj z najlepszych propozycji już dziś!</p>
              <h2>Wynajem rowerów - jak wypożyczyć sprzęt?</h2>
              <p>W Decathlon Rent znajdziesz wszystko, czego potrzebujesz. Jeśli jesteś sympatykiem rowerowych wypraw, powinieneś sprawdzić, co możemy Ci w tym temacie zaproponować.</p>
              <p>Wśród modeli do wypożyczenia w Decathlon Rent dostępne są rowery miejskie, trekkingowe, szosowe czy rowery MTB. W asortymencie naszej wypożyczalni sprzętu sportowego znajdują się również rowery składane, dziecięce i elektryczne, a także akcesoria niezbędne dla aktywnych cyklistów szukających wrażeń na trasach: przyczepki rowerowe dla dzieci, bagażniki samochodowy na rowery, bagażniki rowerowy na hak czy foteliki rowerowe dla dzieci. Wszystkie produkty pochodzą od naszych uznanych producentów i wieloletnich partnerów.</p>
              <p>Nie zapominamy też o pasjonatach kolarstwa, którzy lubią spędzać czas w domowym zaciszu. Mamy im do zaoferowania szeroką gamę najwyższej klasy trenażerów oraz rowerów stacjonarnych. Każdy miłośnik dwóch kółek znajdzie u nas coś dla siebie.</p>
              <h2>Wypożyczalnia nart i sprzętu zimowego - Decathlon Rent</h2>
              <p>Z usługą Decathlon Rent <a href="https://rent.decathlon.pl/wypozyczalnia-nart">wynajem nart</a> zjazdowych, nart zjazdowych damskich, nart zjazdowych dla dzieci, a do tego kijków narciarskich dla dorosłych i młodszych fanów białego szaleństwa nie będzie żadnym problemem. Produkty najlepszych producentów dostępne i sprawdzone przez setki klientów, a oprócz tego fachowa pomoc w doborze produktów naszych doradców w punktach wypożyczeń w Twoim mieście: Warszawie, Trójmieście, Poznaniu, Olsztynie czy Katowicach.</p>
              <p>Wybierz, którym modelem jesteś zainteresowany. Zaznacz. Zarezerwuj. Odbierz w naszym punkcie. To takie proste i wygodne. Zapraszamy!</p>
              <h2>Wypożyczalnia hulajnóg</h2>
              <p>Wynajmij hulajnogę elektryczną lub tradycyjną wraz z Decathlon Rent. Jeżeli zadajesz sobie częste obecnie pytanie czy wypożyczyć, czy też kupić hulajnogę, to my pomożemy Tobie rozwiać wszelkie wątpliwości. Sprawdź naszą bazę zyskujących na popularności dwukołowców i przekonaj się samemu, czy hulajnoga jest odpowiednim pojazdem dla Ciebie. Za niewielką opłatą możesz dowiedzieć się także, czy hulajnogi do jazdy na krótkich i dłuższych dystansach spełniają Twoje oczekiwania. Wygodne, bezpieczne, zaopatrzone w podwójną amortyzację czy hamulce na kierownice pojazdy umożliwiają jazdę szybszą i o wiele przyjemniejszą niż przemieszczanie się pieszo. Przekonaj się, która z hulajnóg dostępna w naszym asortymencie będzie dla Ciebie najlepsza.</p>
              <h2>Wypożyczalnia sprzętu turystycznego i akcesoriów na terenie całej Polski</h2>
              <p>W Decathlon Rent mamy produkty do dyspozycji dla pasjonatów podróży małych i dużych. Wśród nich znajdują się trwałe i sprawdzone jakościowo namioty kempingowe, plecaki turystyczne i trekkingowe, nosidełka turystyczne, materace dmuchane czy też przeróżne akcesoria, w tym raki, czekany lub kijki turystyczne.</p>
              <p>Być może szukasz też sprzętu turystycznego, ale nie jesteś pewien, który dokładnie powinieneś kupić? Zapraszamy do sprawdzenia naszych produktów, które pomogą Tobie dokonać zakupu. Wybierz model, którym jesteś zainteresowany. <a href="https://rent.decathlon.pl/wypozyczalnia-sprzetu-turystycznego">Wynajmij sprzęt turystyczny</a> w Warszawie, Wrocławiu, Trójmieście i innych miastach Polski. Z Decathlon Rent zawsze warto rozpocząć lub kontynuować turystyczną przygodę!</p>
              <h2>Wypożyczalnia sprzętu wodnego</h2>
              <p>Już teraz nie musisz mieszkać nad morzem lub na Warmii i Mazurach, aby zaopatrzyć się czasowo w produkty, dzięki którym możesz uprawiać sporty wodne. W Decathlon Rent uzyskujesz możliwość <a href="https://rent.decathlon.pl/wypozyczalnia-kajakow">wynajęcia kajaków</a> pneumatycznych, kajaków turystycznych i kajaków wysokociśnieniowych, materaców pływających, desek SUP, a także desek do kitesurfingu lub kamizelek asekuracyjnych. Sprzęt wodny dla początkujących, tych bardziej zaawansowanych czy osób, które bez sportów wodnych po prostu nie potrafią funkcjonować. Dzięki Decathlon Rent możesz wynająć wysokiej klasy produkty, sprawdzić ich przydatność w praktyce i oddać się w pełni swojej pasji.</p>

            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export default SeoPage;
