import React, { useState } from 'react';

import { Modal } from '..';
import informationSvg from './information.svg';
import bikeSvg from './bike.svg';
import defaultSvg from './default_2.svg';
import giftSvg from './gift.svg';
import giftCardSvg from './giftcard.svg';
import leafSvg from './leaf.svg';
import logoSvg from './logo.svg';
import saleSvg from './sale_01.svg';
import shopSvg from './shop.svg';

import css from './ActiveProgramModal.css';
import { FormattedMessage } from 'react-intl';

const ActiveProgramModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className={css.pointsTextContainers}>
        <span className={css.pointsText}>
          <FormattedMessage id="ActiveProgramModal.informationAboutPoints1" />
        </span>
        <span className={css.points}>
          <FormattedMessage id="ActiveProgramModal.informationAboutPoints2" />
        </span>
        <span className={css.pointsText}>
          <FormattedMessage id="ActiveProgramModal.informationAboutPoints3" />
        </span>
        <button className={css.btnInfoIcon} onClick={() => setIsOpen(true)} type="button">
          <img src={informationSvg} />
        </button>
      </div>
      <Modal
        id={'activeProgram'}
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        scrollLayerClassName={css.modalScrollLayer}
        closeButtonClassName={css.modalCloseButton}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onManageDisableScrolling={() => {}}
        usePortal
        closeButtonMessage={<span></span>}
      >
        <div className={css.container}>
          <h2 className={css.heading}>
            <FormattedMessage id="ActiveProgramModal.heading" />
          </h2>
          <div className={css.contentContainer}>
            <div className={css.halfContainer}>
              <div className={css.rowContainer}>
                <div className={css.itemContainer}>
                  <img src={logoSvg} />
                  <p className={css.title}>
                    <FormattedMessage id="ActiveProgramModal.title1" />
                  </p>
                  <p className={css.text}>
                    <FormattedMessage id="ActiveProgramModal.text1" />
                  </p>
                </div>
                <div className={css.itemContainer}>
                  <img src={saleSvg} />
                  <p className={css.title}>
                    <FormattedMessage id="ActiveProgramModal.title2" />
                  </p>
                  <p className={css.text}>
                    <FormattedMessage id="ActiveProgramModal.text2" />
                  </p>
                </div>
              </div>
              <div className={css.rowContainer}>
                <div className={css.itemContainer}>
                  <img src={giftSvg} />
                  <p className={css.title}>
                    <FormattedMessage id="ActiveProgramModal.title3" />
                  </p>
                  <p className={css.text}>
                    <FormattedMessage id="ActiveProgramModal.text3" />
                  </p>
                </div>
                <div className={css.itemContainer}>
                  <img src={giftCardSvg} />
                  <p className={css.title}>
                    <FormattedMessage id="ActiveProgramModal.title4" />
                  </p>
                  <p className={css.text}>
                    <FormattedMessage id="ActiveProgramModal.text4" />
                  </p>
                </div>
              </div>
            </div>
            <div className={css.halfContainer}>
              <div className={css.rowContainer}>
                <div className={css.itemContainer}>
                  <img src={shopSvg} />
                  <p className={css.title}>
                    <FormattedMessage id="ActiveProgramModal.title5" />
                  </p>
                  <p className={css.text}>
                    <FormattedMessage id="ActiveProgramModal.text5" />
                  </p>
                </div>
                <div className={css.itemContainer}>
                  <img src={bikeSvg} />
                  <p className={css.title}>
                    <FormattedMessage id="ActiveProgramModal.title6" />
                  </p>
                  <p className={css.text}>
                    <FormattedMessage id="ActiveProgramModal.text6" />
                  </p>
                </div>
              </div>
              <div className={css.rowContainer}>
                <div className={css.itemContainer}>
                  <img src={defaultSvg} />
                  <p className={css.title}>
                    <FormattedMessage id="ActiveProgramModal.title7" />
                  </p>
                  <p className={css.text}>
                    <FormattedMessage id="ActiveProgramModal.text7" />
                  </p>
                </div>
                <div className={css.itemContainer}>
                  <img src={leafSvg} />
                  <p className={css.title}>
                    <FormattedMessage id="ActiveProgramModal.title8" />
                  </p>
                  <p className={css.text}>
                    <FormattedMessage id="ActiveProgramModal.text8" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <a
            href={'https://www.decathlon.pl/program-lojalnosciowy_lp-0BCFGW'}
            className={css.link}
            target="_blank"
          >
            <FormattedMessage id={'SectionActiveProgram.button'} />
          </a>
        </div>
      </Modal>
    </>
  );
};

export default ActiveProgramModal;
